import { deepFreeze } from 'grommet/utils';

const vitorTema = deepFreeze({
  global: {
    colors: {
      brand: '#E9191F',
      focus: '#000000',
      placeholder: '#707070',
    },
    control: {
      border: {
        width: '0',
      },
    },
  },
  heading: {
    level: {
      1: {
        medium: {
          color: '#E9191F',
          size: '30px',
          maxWidth: '100%',
        },
      },
      3: {
        medium: {
          size: '20px',
          maxWidth: '100%',
        },
      },
    },
  },
  button: {
    border: {
      radius: '0',
    },
  },
  textInput: {
    extend: {
      backgroundColor: '#f2f2f2',
      fontWeight: '300',
    },
  },
  textArea: {
    extend: {
      backgroundColor: '#f2f2f2',
    },
  },
});

export default vitorTema;
