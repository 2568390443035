import React from 'react';
import { Box, Button, Grommet, Heading } from 'grommet';
import vitorTema from './theme';
import Mapa from './mapa';
import Whatsapp from '../assets/whatsapp.svg';
import Pin from '../assets/pin.svg';
import getContactData from './getContactData';

const Contato = () => {
  const {
    endereco, telefone, whatsappLink,
  } = getContactData();

  return (
    <Grommet theme={vitorTema}>
      <div className="o-container  u-mt-s2 contact-area">
        <div className="o-container--grid-12">
          <div className="u-center-10 o-container--grid-10" id="contato">
            <div className="u-span-3 u-mobile-center">
              <Heading margin="none" className="u-main-color u-mb-s1">
                Entre em contato
            </Heading>
              <div className="u-mb-s1 contact u-mobile-center" id="contato">
                <div>
                  <Box direction="row" align="center" className="u-mb-s1">
                    <Whatsapp />
                    {telefone}
                  </Box>
                </div>
                <div className="u-mb-s1">
                  <a
                    href={whatsappLink}
                  >
                    <Button
                      label="Envie um Whatsapp"
                      primary
                    />
                  </a>
                </div>
                <Box direction="row">
                  <Pin />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: endereco,
                    }}
                    className=""
                  />
                </Box>
              </div>
            </div>
            <div className="u-last-6-of-10">
              <Mapa />
            </div>
          </div>
        </div>
        <hr className="u-mt-s2" />
      </div>
    </Grommet>
  );
};

export default Contato;
