import { graphql, useStaticQuery } from 'gatsby';

const getContactData = () => {
  const contato = useStaticQuery(
    graphql`
    query contato_options {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                endereco
                telefone
                whatsappLink: whatsapp_link
              }
            }
          }
        }
      }     
    `,
  );
  return contato.allWordpressAcfOptions.edges[0].node.options;
};
export default getContactData;
