/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import MenuVitor from './menuvitor';

// import "./layout.css"
import './scss/style.scss';

// import "./fonts.css"

const Layout = ({ children, isHome }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  function changeMenuOpen() {
    setMenuOpen(!menuOpen);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function handleStateChange(state) {
    // console.log('aqui');
    setMenuOpen(state.isOpen);
  }

  return (
    <div id="page-wrap">
      <Header changeMenuOpen={changeMenuOpen} isHome={isHome} />
      <MenuVitor
        isOpen={menuOpen}
        onStateChange={state => handleStateChange(state)}
        closeMenu={closeMenu}
        isHome={isHome}
      />
      <main>{children}</main>
      <Footer isHome={isHome} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool.isRequired,
};

export default Layout;
