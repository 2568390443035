import React from 'react';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import { Box } from 'grommet';
import { Link as LocalLink } from 'react-scroll';
import { Link } from 'gatsby';
import Harpa from '../assets/harpa.svg';
import Email from '../assets/email.svg';
import Whatsapp from '../assets/whatsapp.svg';
import getSiteMenu from './getSiteMenu';
import getContactData from './getContactData';

//import './scss/style.scss';

const styles = {
  bmBurgerButton: {
    display: 'none',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px',
  },
  bmBurgerBars: {
    background: 'transparent',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    transition: '',
    // opacity: '0'
  },
  bmMenu: {
    background: '#ffffff',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    // transition: 'transform 0.1s ease 0s, opacity 1s ease-in 0.5s'
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    transition: '',
    // zIndex: -10,
    // transition: 'transform 0.1s ease 0s, opacity 10s ease-in 0.5s'
    // transition: 'opacity 5s'
    // transform: 'none'
  },
};


const MenuVitor = ({
  isOpen, onStateChange, closeMenu, isHome,
}) => {
  const menuItems = getSiteMenu();
  const { email, whatsappLink } = getContactData();
  const lastIndex = menuItems.allWordpressWpApiMenusMenusItems.edges[0].node.items.length - 1;
  if (isHome) {
    return (
      <Menu
        isOpen={isOpen}
        left
        pageWrapId="page-wrap"
        styles={styles}
        width="100%"
        onStateChange={onStateChange}
        className={
                  isOpen ? 'menu-active' : 'menu-closed'
              }
        closeMenu={closeMenu}
      >
        <Box
          direction="row"
          justify="center"
          align="center"
          style={{ display: 'flex' }}
          className="home-link"
        >
          <Link
            to="/"
            onClick={closeMenu}
          >
            <Harpa />
          </Link>
        </Box>

        <div className="vertical-menu__center">
          <Box
            direction="row"
            justify="center"
            align="center"
            className="social-networks"
          >
            <a href={whatsappLink}>
              <Whatsapp />
            </a>
            <a href={`mailto:${email}`}>
              <Email />
            </a>
          </Box>
        </div>

        <ul className="vertical-menu">
          {menuItems.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
            item => (
              <li key={item.object_slug} className={item.classes}>
                <LocalLink
                  to={item.url.slice(1)}
                  spy
                  hashSpy
                  smooth
                  duration={500}
                  offset={92}
                  activeClass="active"
                  onClick={closeMenu}
                >
                  {item.title}
                </LocalLink>
              </li>
            ),
          )}
        </ul>
      </Menu>
    );
  }
  return (
    <Menu
      isOpen={isOpen}
      left
      pageWrapId="page-wrap"
      styles={styles}
      width="100%"
      onStateChange={onStateChange}
      className={
                isOpen ? 'menu-active' : 'menu-closed'
            }
      closeMenu={closeMenu}
    >
      <li className="vertical-menu__center">
        <Box
          direction="row"
          justify="center"
          align="center"
          className="social-networks"
        >
          <a href={whatsappLink}>
            <Whatsapp />
          </a>
          <a href={`mailto:${email}`}>
            <Email />
          </a>
        </Box>
      </li>
      <ul className="vertical-menu">
        {menuItems.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
          (item, i) => {
            if (i === lastIndex) {
              return (
                <li key={item.object_slug} className={item.classes}>
                  <LocalLink to={item.url.slice(1)} spy hashSpy smooth duration={500} offset={92} activeClass="active" onClick={closeMenu}>
                    {item.title}
                  </LocalLink>
                </li>
              );
            }
            return (
              <li key={item.object_slug} className={item.classes}>
                <Link
                  to={`/#${item.url.slice(1)}`}
                >
                  {item.title}
                </Link>
              </li>
            );
          },
        )}
      </ul>
    </Menu>
  );
};

MenuVitor.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onStateChange: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isHome: PropTypes.bool.isRequired,
};

export default MenuVitor;
