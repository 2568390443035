import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { Grommet, Box } from 'grommet';

import { Link as LocalLink } from 'react-scroll';
import vitorTema from './theme';
import Logo from '../assets/logo_horizontal.svg';
import Whatsapp from '../assets/whatsapp.svg';
import getSiteMenu from './getSiteMenu';
import getContactData from './getContactData';


//import './scss/style.scss';

const Header = ({ changeMenuOpen, isHome }) => {
  const menu = getSiteMenu();
  const { email, whatsappLink } = getContactData();

  const lastIndex = menu.allWordpressWpApiMenusMenusItems.edges[0].node.items.length - 1;

  if (isHome) {
    return (
      <Grommet theme={vitorTema}>
        <header className="c-header">
          <div className="o-container">
            <Box justify="between" direction="row-responsive">
              <h1 className="is-mobile-invisible">
                <Link
                  to="/"
                  style={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                  className="c-header__home"
                >
                  <Logo />
                </Link>
              </h1>
              <div className="container-menu">
                <Box
                  direction="row"
                  justify="center"
                  align="center"
                  className="social-networks"
                >
                  <a href={whatsappLink}>
                    <Whatsapp />
                  </a>
                </Box>
                <Box direction="row" style={{ height: '100%' }}>
                  <ul className="menu">
                    {menu.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                      item => (
                        <li key={item.object_slug} className={item.classes}>
                          <LocalLink to={item.url.slice(1)} spy hashSpy smooth duration={500} offset={600} activeClass="active">
                            {item.title}
                          </LocalLink>
                        </li>
                      ),
                    )}
                  </ul>
                  <div className="menu-hamburger">
                    <div
                      className="o-hamburger o-hamburger--slider js-offmenu-toggle"
                      type="button"
                      onClick={() => changeMenuOpen()}
                    >
                      <div className="o-hamburger__box">
                        <div className="o-hamburger__inner" />
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            </Box>
          </div>
        </header>
      </Grommet>
    );
  }

  return (
    <Grommet theme={vitorTema}>
      <header className="c-header">
        <div className="o-container">
          <Box justify="between" direction="row-responsive">
            <h1 className="is-mobile-invisible">
              <Link
                to="/"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                }}
                className="c-header__home"
              >
                <Logo />
              </Link>
            </h1>
            <div className="container-menu">
              <Box
                direction="row"
                justify="center"
                align="center"
                className="social-networks"
              >
                <a href={whatsappLink}>
                  <Whatsapp />
                </a>
              </Box>
              <ul className="menu">
                {menu.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                  (item, i) => {
                    if (i === lastIndex) {
                      return (
                        <li key={item.object_slug}>
                          <LocalLink to={item.url.slice(1)} spy hashSpy smooth duration={500} offset={92} activeClass="active">
                            {item.title}
                          </LocalLink>
                        </li>
                      );
                    }
                    return (
                      <li key={item.object_slug}>
                        <Link
                          to={`/#${item.url.slice(1)}`}
                        >
                          {item.title}
                        </Link>
                      </li>
                    );
                  },
                )}
              </ul>
              <div className="menu-hamburger is-mobile-only">
                <div
                  className="o-hamburger o-hamburger--slider js-offmenu-toggle"
                  type="button"
                  onClick={() => changeMenuOpen()}
                >
                  <div className="o-hamburger__box">
                    <div className="o-hamburger__inner" />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </header>
    </Grommet>
  );
};

Header.propTypes = {
  changeMenuOpen: PropTypes.func.isRequired,
  isHome: PropTypes.bool.isRequired,
};

export default Header;
