import React from 'react';
import { Marker, GoogleMap, useLoadScript } from '@react-google-maps/api';
import getCoordinates from './getCoordinates';

const estilosMapa = require('../components/tema-mapa.json');

function Mapa() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDajrYTec1lLbkf4o76N6EVEizS2tx3o18',
    preventGoogleFonts: true,
  });

  const renderMap = () => {
    const { lat, lng } = getCoordinates();
    const center = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };
    return (
      <GoogleMap
        id="mapa"
        center={center}
        options={
    {
      styles: estilosMapa,
      zoom: 13,
    }
  }
      >
        <Marker
          position={center}
        />
      </GoogleMap>
    );
  };


  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : (<div>erro</div>);
}

export default Mapa;
