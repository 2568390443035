import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

import { Grommet, Box } from 'grommet';

import { Link as LocalLink } from 'react-scroll';
import vitorTema from './theme';
import Logo from '../assets/logo_horizontal.svg';
import Harpa from '../assets/harpa.svg';
import Email from '../assets/email.svg';
import Whatsapp from '../assets/whatsapp.svg';
import getSiteMenu from './getSiteMenu';
import getContactData from './getContactData';

const Footer = ({ isHome }) => {
  // console.log(isHome);
  const menu = getSiteMenu();
  const { email, whatsappLink } = getContactData();

  const lastIndex = menu.allWordpressWpApiMenusMenusItems.edges[0].node.items.length - 1;
  if (isHome) {
    return (
      <Grommet theme={vitorTema}>
        <footer className="c-footer">
          <div className="o-container">
            <Box justify="between" direction="row-responsive">
              <h1 className="footer-link-home">
                <Link
                  to="/"
                  style={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                  className="c-footer__brand"
                >
                  <Logo className="is-mobile-invisible brand-footer" />
                  <Harpa className="is-mobile-only harpa" />
                </Link>
              </h1>
              <Box direction="row-responsive" justify="center" align="center">
                <Box
                  direction="row"
                  justify="center"
                  align="center"
                  className="social-networks"
                >
                  <a href={whatsappLink}>
                    <Whatsapp />
                  </a>
                </Box>
                <ul className="menu menu--footer">
                  {menu.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                    item => (
                      <li key={item.object_slug}>
                        <LocalLink to={item.url.slice(1)} spy hashSpy smooth duration={500} offset={92} activeClass="active">
                          {item.title}
                        </LocalLink>
                      </li>
                    ),
                  )}
                </ul>
              </Box>
            </Box>
          </div>
        </footer>
      </Grommet>
    );
  }
  return (
    <Grommet theme={vitorTema}>
      <footer className="c-footer">
        <div className="o-container">
          <Box justify="between" direction="row-responsive">
            <h1 className="footer-link-home">
                <Link
                  to="/"
                  style={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                  className="c-footer__brand"
                >
                  <Logo className="is-mobile-invisible brand-footer" />
                  <Harpa className="is-mobile-only harpa" />
                </Link>
              </h1>
            <Box direction="row-responsive" justify="center" align="center">
              <Box
                direction="row"
                justify="center"
                align="center"
                className="social-networks"
              >
                <a href={whatsappLink}>
                  <Whatsapp />
                </a>
              </Box>
              <ul className="menu menu--footer">
                {menu.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                  (item, i) => {
                    if (i === lastIndex) {
                      return (
                        <li key={item.object_slug}>
                          <LocalLink to={item.url.slice(1)} spy hashSpy smooth duration={500} offset={92} activeClass="active">
                            {item.title}
                          </LocalLink>
                        </li>
                      );
                    }
                    return (
                      <li key={item.object_slug}>
                        <Link
                          to={`/#${item.url.slice(1)}`}
                        >
                          {item.title}
                        </Link>
                      </li>
                    );
                  },
                )}
              </ul>
            </Box>
          </Box>
        </div>
      </footer>
    </Grommet>
  );
};

Footer.propTypes = {
  isHome: PropTypes.bool.isRequired,
};

export default Footer;
