import { graphql, useStaticQuery } from 'gatsby';

const getCoordinates = () => {
  const coordinates = useStaticQuery(
    graphql`
    query options {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                mapa {
                  address
                  lat
                  lng
                }
              }
            }
          }
        }
      }
    `,
  );
  return coordinates.allWordpressAcfOptions.edges[0].node.options.mapa;
};
export default getCoordinates;
