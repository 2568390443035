import { graphql, useStaticQuery } from 'gatsby';

const getSiteMenu = () => {
  const menu = useStaticQuery(
    graphql`
    {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              name
              count
              items {
                order
                title
                url
                classes
                object_slug
              }
            }
          }
        }
      }
    `,
  );
  return menu;
};
export default getSiteMenu;
